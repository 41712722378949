const people = [
  // {
  //   name: "B. Kumar",
  //   role: "Co-Founder / CEO",
  //   university: "Delhi University",
  //   linkedinLink: "https://www.linkedin.com/in/b-kumar-877755204/",
  //   imageUrl:
  //     "https://media.licdn.com/dms/image/D5603AQHAKTDk3Y1_aw/profile-displayphoto-shrink_400_400/0/1718219606900?e=1727913600&v=beta&t=73NOUZ2RBiwS8ig61mZCIYSzu0Byx6YAxD7DcZALpUY",
  // },
  {
    name: "Manideep",
    linkedinLink: "https://www.linkedin.com/in/manideepkadari/",
    university: "IIM",
    role: "AI Product Manager",
    imageUrl:
      "https://media.licdn.com/dms/image/D4D03AQHWT_rG4Q1J5w/profile-displayphoto-shrink_800_800/0/1718230067116?e=1729123200&v=beta&t=4Jt5Jli0f8yfYkcsXpXBeWihVcvMgHv7mbviYkTmP60",
  },{
    name: "Yash",
    linkedinLink: "https://www.linkedin.com/in/yash-raj-hans-7827a3224/",
    university: "MAIT, Delhi",
    role: "Full Stack Developer",
    imageUrl: "https://media.licdn.com/dms/image/D5603AQEuUxyK11Yq2Q/profile-displayphoto-shrink_400_400/0/1707623906579?e=1728518400&v=beta&t=CcpPo0Py-Il8oPzHrJmta94JATNBq_3yBIdk2U3ZW5c",
  },
  {
    name: "Tushar",
    linkedinLink: "https://www.linkedin.com/in/tushar-batra-4407bb21a/",
    university: "BITS PILANI",
    role: "Product Manager",
    imageUrl:
      "https://media.licdn.com/dms/image/D5603AQGZsjZa18SOfw/profile-displayphoto-shrink_400_400/0/1719416203627?e=1727913600&v=beta&t=TCShZRef5_7cYkw31pGye-QCzPqv0YHz27EW16hkpnM",
  },
  {
    name: "Putul",
    linkedinLink: "https://www.linkedin.com/in/putul-singh-80036b25a/",
    role: "AI/ML R&D",
    university: "NIT HAMIRPUR",
    imageUrl:
      "https://media.licdn.com/dms/image/D5603AQGZKpV5xi0FXw/profile-displayphoto-shrink_400_400/0/1718605143639?e=1728518400&v=beta&t=h3MUeWiRQu_uL2F9ubgYdT75rFXTYEfM7OBBFdMPYQM",
  },
  {
    name: "Shivam",
    linkedinLink: "https://www.linkedin.com/in/shivam-uppal-58b785270/",
    role: "AI/ML R&D",
    university: "NIT HAMIRPUR",
    imageUrl:
      "https://media.licdn.com/dms/image/D5603AQFQTAPvO0t2qA/profile-displayphoto-shrink_400_400/0/1718438643733?e=1727913600&v=beta&t=Cyv_7tjAEWxiR1QHi6l6r4atuvRaHTkRj6mt-KGjG2A",
  },
  {
    name: "Rahul",
    linkedinLink: "https://www.linkedin.com/in/rahul-marban/",
    role: "Full Stack Developer",
    university: "SRMIST",
    imageUrl:
      "https://media.licdn.com/dms/image/v2/D5635AQHmYFJY84qI8A/profile-framedphoto-shrink_800_800/profile-framedphoto-shrink_800_800/0/1719678492722?e=1723766400&v=beta&t=dz6NMemgpTZP99XD_HGg5LNZEp9mJKLCRA0fKi_CHnA",
  },
  {
    name: "Nishant",
    linkedinLink: "https://www.linkedin.com/in/ndalal0207/",
    role: "Full Stack Developer",
    university: "VIT",
    imageUrl:
      "https://media.licdn.com/dms/image/D5603AQH4gjtEo0BgeA/profile-displayphoto-shrink_400_400/0/1718298953073?e=1727913600&v=beta&t=Xp4-vN_jc4i5FOkVGCiyJW56Rnw66ALres3WHiu4-4A",
  },
  {
    name: "Sumen",
    linkedinLink: "https://www.linkedin.com/in/sumenchalla/",
    university: "IIT KANPUR",
    role: "Ex-AI/ML R&D",
    imageUrl:
      "https://media.licdn.com/dms/image/D5603AQGNMmLBjfdbQQ/profile-displayphoto-shrink_400_400/0/1719564999506?e=1727913600&v=beta&t=uu2SVkuH0djRSs9TGDpcC453eHEYwsAr6yDUgrkKj9o",
  },
  {
    name: "Ishaan",
    linkedinLink: "https://www.linkedin.com/in/ishaan-khurana-398114212/",
    university: "SRM IST",
    role: "Ex-Full Stack Developer",
    imageUrl:
      "https://media.licdn.com/dms/image/D5603AQFtXiMX5LNW7w/profile-displayphoto-shrink_400_400/0/1690649822961?e=1727913600&v=beta&t=fzULFypErfjMusL_tDqm8DLsicAFgdr4OIR5vUS8aR4",
  },
  {
    name: "Om",
    linkedinLink: "https://www.linkedin.com/in/om-patel-b55a3424b/",
    role: "Ex-Full Stack Developer",
    university: "SRM IST",
    imageUrl:
      "https://media.licdn.com/dms/image/v2/D4D03AQGdJyaD3LWMqw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1723206409944?e=1728518400&v=beta&t=02UQNmqaCzEeRT0KgQYAKUqmjZGfEBm1U7OCdmUO8sc",
  },
  {
    name: "Rishang",
    linkedinLink: "https://www.linkedin.com/in/rishang-kumar/",
    role: "Ex-Full Stack Developer",
    university: "GNIOT",
    imageUrl:
      "https://media.licdn.com/dms/image/D5603AQHDGmaeIPbLgg/profile-displayphoto-shrink_400_400/0/1703104626849?e=1727913600&v=beta&t=De00JZ7Bx5kQZ5zb8oT9GNgjJ_V9wgmdQWj7Fg-AFC0",
  }
  // More people...
];

export default people;