import React from "react";
import "../../index.css";
import "./Team.css";
import AceHubImage from "../../assets/hero/image.png";

const HeroUpSkillAi = ({data}) => {
  const { Heading, SubHeading, Content, Image ,Direction} = data;
  // console.log(data);
   
  return (
    <div className="mt-32 mb-20 m-2 rounded-2xl ">
      <div className="mx-auto max-w-7xl rounded-2xl pulse_running_border overflow-hidden">
        <div style={{flexDirection:Direction}} className="w-full h-full relative isolate overflow-hidden bg-gray-900 pt-16 shadow-2xl sm:px-4  sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-10 lg:px-8 lg:pt-0 lg:pb-12">
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          >
            <circle
              r={512}
              cx={512}
              cy={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>

          <div className="lg:w-4/12 md:w-full mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-28 lg:text-left px-4">
            <h2 className=" font-bold tracking-tight text-white sm:text-2xl ">
              <p className="word-spacing-2 text-richYellow text-3xl">
                {Heading}
              </p>

              <p className="mt-6  word-spacing lg:text-2xl">
                {SubHeading}
              </p>
            </h2>
            <p className="mt-6 md:text-sm sm:text-sm text-gray-300">
              {Content}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                href="https://acehub.ai"
                target="_blank"
                className="rounded-md  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white bg-richYellow"
              >
                Get started
              </a>
              <a
                href="https://acehub.ai"
                target="_blank"
                className="text-sm font-semibold leading-6 text-white"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>

          <div className="lg:w-8/12  mx-auto  md:w-11/12 w-11/12 relative lg:h-[55vh] md:h-[60vh] sm:h-[50vh] h-[35vh] lg:mt-24 md:mt-12 sm:mt-8 lg:mt-8shine-pulse m-4 ">
            <img
              loading="lazy"
              style={{ height: "100%", width: "100%" }}
              alt="App screenshot"
              src={Image}
              // width={1824}
              // height={1080}
              className="object-cover object-left  hover:opacity-[1] opacity-[.80] bg-zinc-700 absolute left-0 top-0  rounded-md bg-white/5 ring-1 ring-white/10 "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroUpSkillAi;
