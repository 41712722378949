import JoinUs from "./JoinUs";
import image1 from "../../assets/carousel/Acehub_bg.png";
export default function Test() {
    return (
      <section className="relative isolate overflow-hidden bg-gray-900 text-gray-100 px-6 py-24 sm:py-32 lg:px-8"
      >
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.900),theme(colors.gray.900))] opacity-20" />
        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-gray-900 shadow-xl shadow-indigo-900/10 ring-1 ring-indigo-800 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
          <div className="mx-auto max-w-2xl lg:max-w-4xl"
          
          >
              
              <p style={{textAlign: "center", fontWeight: "bold", fontSize: "29px"}}>VISION</p>
              <figure className="mt-10">
                  <blockquote
                      className="text-center text-xl font-semibold leading-8 text-gray-100 sm:text-xl sm:leading-9 italic text-richYellow opacity-80">
                      <span>Empowering Learners to Succeed.</span>
                  </blockquote>
                  
                  <figcaption className="mt-10">
                      <img
                          alt=""
                          src="https://media.licdn.com/dms/image/D5603AQHAKTDk3Y1_aw/profile-displayphoto-shrink_400_400/0/1718219606900?e=1728518400&v=beta&t=fzj3_VTE7TxXfVymva69rKKgC9Lza5GO4fZm7VXztug"
                          className="mx-auto h-10 w-10 rounded-full"
                      />
                      <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                          <div className="font-semibold text-gray-100">B. Kumar</div>
                          <svg width={3} height={3} viewBox="0 0 2 2" aria-hidden="true" className="fill-gray-100">
                              <circle r={1} cx={1} cy={1}/>
                          </svg>
                          <div className="text-gray-400">CEO of Neugence</div>
                          <svg width={3} height={3} viewBox="0 0 2 2" aria-hidden="true" className="fill-gray-100">
                              <circle r={1} cx={1} cy={1}/>
                          </svg>
                          <a className="text-gray-400 underline" href="https://www.linkedin.com/in/b-kumar-877755204/" target="_blank"
                             rel="noopener noreferrer">LinkedIn</a>
                      </div>
                  </figcaption>
              </figure>
          </div>
      </section>
    )
}
  