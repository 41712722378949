import React from "react";
import Carousel from "../refactor/Carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { EffectFade, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-coverflow";
import "../../index.css";
import image1 from "../../assets/carousel/Acehub_bg.png";
import image2 from "../../assets/carousel/carousel2.jpg";
const Career = () => {
  const people = [
    {
      role: "Product Manager",
      imageUrl:
        "https://media.istockphoto.com/id/1909556559/photo/womanhand-holding-light-bulb-show-business-process-management-icons-concept-of-achieving.webp?b=1&s=170667a&w=0&k=20&c=IRqzz7ftb4vkTaCZIu8vpGF_QeilpOAl7UaTa0UcJx4=",
    },
    {
      role: "AI/ML R&D",

      imageUrl:
        "https://media.istockphoto.com/id/1255527336/photo/ai-concept-deep-learning-computer-programming.webp?b=1&s=170667a&w=0&k=20&c=RkeNcU6p_bJsFErPCzSoZBaL9bNXv9zTMs72U6e2Xik=",
    },

    {
      role: "Full Stack Developer",

      imageUrl:
        "https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGZ1bGwlMjBzdGFjayUyMGRldmVsb3BlcnxlbnwwfHwwfHx8MA%3D%3D",
    },

    {
      name: "Want to Join Us?",
      linkedinLink: "",
      role: "Intern",
      imageUrl:
        "https://plus.unsplash.com/premium_photo-1683690944936-18103fa341dd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aW50ZXJufGVufDB8fDB8fHww",
    },

    // More people...
  ];
  return (
    <div className="mt-24 flex flex-col justify-center items-center">
      <Swiper
        className="md:h-[80vh] md:w-[80%] w-[80%] h-[60vh] rounded-xl"
        modules={[Pagination, Scrollbar, A11y, EffectCoverflow, Autoplay]}
        spaceBetween={50}
        effect="coverflow"
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide
          className=" backdrop-opacity-[0.40]	backdrop-brightness-50 backdrop-blur-3xl"
          style={{
            backgroundImage: `url(${image1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "opacity-50",
          }}
        >
          <div className=" flex flex-col items-center md:space-y-4 space-y-2 drop-shadow-2xl bg-gray-900/50 backdrop-blur-md backdrop-saturate-150 py-2 px-8">
            <div className="md:text-6xl text-xl font-bold text-richYellow">
              Upskill in AI ML
            </div>
            <div className="md:text-xl md:font-semibold text-xs text-center md:px-0 px-4 text-white">
              We provide you real world projects, code, notebooks, tutorials,
              and team settings to ramp quickly on AI.
            </div>
            <div className="learn-more bg-white text-black md:py-2 md:px-4 text-sm px-1 py-1 rounded-full">
              Learn more
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide
        className=""
        style={{
          backgroundImage: `url(${image2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="text-white flex flex-col items-center md:space-y-4 space-y-2 ">
          <div className="md:text-6xl md:font-semibold text-xl font-semibold">
            Job Applications For You
          </div>
          <div className="md:text-xl md:font-semibold text-xs text-center md:px-0 px-4">
             Auto Job Applications, Provide you Mock Interviews, 1:1 Mentorship & proven path to landing awesome job.
          </div>
          <div className="learn-more bg-white text-black md:py-2 md:px-4 text-sm px-1 py-1 rounded-full">
            Learn more
          </div>
        </div>
      </SwiperSlide> */}
      </Swiper>
      <ul className="w-10/12 flex flex-wrap justify-around items-center mt-20 gap-x-10">
        {people.map((person) => (
          <div className="flex md:h-[30vh] h-[80vh] flex-col  items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 mb-4">
            <div className="h-full  lg:w-4/12 md:w-4/12 w-11/12">
              <img
                loading="lazy"
                className="object-cover w-full rounded-t-lg  md:h-auto md:w-48 md:rounded-none md:rounded-s-lg  "
                src={person.imageUrl}
                style={{ width: "100%", height: "100%" }}
                alt=""
              ></img>
            </div>
            <div className="lg:w-8/12 md:w-8/12 w-11/12 h-full flex flex-col  justify-between p-4 leading-normal">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900  text-richYellow">
                {person.role}
              </h5>
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                Here are the biggest enterprise technology acquisitions of 2024
                so far, Join us here for {person.role}.
              </p>
              <button className="pulse_running_border text-xl text-white py-2 w-32">
                {" "}
                Apply
              </button>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Career;
