import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState();

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted) return;

    
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        if (data.country_code !== 'IN') {
          setIsVisible(true);
        }
      })
      .catch(error => console.error('Error fetching location:', error));
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-banner fixed top-0 left-0 right-0 bg-zinc-950  text-white p-4 text-center z-[1]">
      <p>We use cookies to improve your experience on our site. By accepting, you agree to our use of cookies.</p>
      <button
        onClick={acceptCookies}
        className="btn-accept bg-green-500 text-white px-4 py-2 mt-2 rounded-full"
      >
        Accept
      </button>
    </div>
  );
};

export default CookieConsent;
