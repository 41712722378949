import React from "react";
import "../../index.css";
import people from './people'

const ok =
  "https://img.freepik.com/free-vector/frozen-arctic-cracked-ice-composition-chunks-melting-ice-sea-against-background-clouds-sunshine-vector-illustration_1284-81849.jpg?t=st=1722431266~exp=1722431866~hmac=dd3f78ec6e118440c13b4ff054a9fb4cbb850085d25720146c75690327392e0f";

const TeamCard = () => {
  return (
    <div className="w-full lg:px-36 py-12 text-white flex flex-col justify-center flex-wrap items-center mt-6">
      <div className="font-bold text-5xl mb-8"> Our Team</div>
      <div className="flex flex-wrap justify-center items-center gap-x-10 gap-y-20">
        {people.map((person, index) => (
          <div className="pulse_running_border">
          <div
            key={index}
            className="relative bg-cover bg-center w-[200px] h-[300px] rounded-xl overflow-hidden group  "
            style={{
              backgroundImage: ``,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(8px)"
      
            }}
          >
            {/* <button className=" bg-gray-900 py-2 px-18 w-[120px] rounded-full font-bold text-xl absolute top-0 left-16 transform -translate-x-1/2 z-10 border-2 transition-all duration-300 group-hover:bg-white group-hover:text-gray-800 group-hover:border-gray-900 group-hover:px-2">
              {person.name}
            </button> */}

            <div className="absolute bottom-[-3vh] left-0 w-full h-0 bg-black bg-opacity-75 text-white flex flex-col justify-center transition-all delay-200  duration-300 group-hover:h-[18vh] z-10 items-center  py-2">
            <p className="text-lg group-hover:text-richYellow">{person.name}</p>
              <p className="text-lg">{person.university}</p>
              <p className="text-lg">{person.role}</p>
            </div>

            <div
              className="absolute top-0 left-0 w-full h-full bg-cover bg-center transition-transform duration-300 delay-[280ms] group-hover:border-white group-hover:border-0 rounded-lg grou-hover:scale-75  grou-hover:opacity-80 grou-hover:blur-lg"
              style={{
                backgroundImage: `url(${person.imageUrl})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            {/* <div className="bottom-[-700px] absolute bottom-0 left-5 w-1/4 h-[12px] bg-zinc-900  text-gray-100 flex flex-col justify-center transition-all duration-700 delay-700 group-hover:-translate-y-[1100px] z-10 items-center border-2 rounded-lg border-white py-6">
              <p className="text-lg">Tech</p>
            </div>
            <div className="bottom-[-700px] absolute bottom-0 left-5 w-1/3 h-[12px] bg-zinc-900  text-gray-100 flex flex-col justify-center transition-all delay-700  duration-700 delay-700 group-hover:-translate-y-[1000px]  z-10 items-center border-2 rounded-lg border-white  px-2 py-6">
              <p className="text-lg">Develop</p>
            </div> */}
            {/* <div className="left-[-200px] absolute bottom-0 left-5 w-1/4 h-[12px] bg-zinc-900  text-gray-100 flex flex-col justify-center transition-all duration-700 delay-700 group-hover:translate-x-[280px] bottom-20  z-10 items-center border-2 rounded-full p-5   border-2 border-white"
            style={{
                backgroundImage: `url(${person.imageUrl})`,
                backgroundSize: "90% 90%",
                backgroundRepeat: "no-repeat",
              }}>
              
            </div> */}
            {/* <div className="bottom-[-400px] absolute bottom-0 left-0 w-full h-[20px] text-gray-100 flex flex-col justify-center transition-all delay-700 font-bold text-2xl  duration-700 delay-700 group-hover:-translate-y-[930px] z-10 items-center  rounded-full p-10  ">
              <p
                className="text-lg
            "
              >
                Hi ! lets connect , and build something together
              </p>
            </div> */}
          </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamCard;