import React, { useState } from 'react';

const faqData = [
  {
    question: "What is UpSkill In AI?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">It's an advanced learning platform we created to offer you various self-paced / interactive / evaluation courses in artificial intelligence and related fields, catering to different educational levels from Bachelors to PhD and professional tracks.</p>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Check out our <a href="https://acehub.ai" className="text-white text-blue-500 hover:underline"> Acehub.ai</a>  website to get started.</p>
      </>
    ),
  },{
    question: "What types of courses do you offer?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">We offer courses in AI, Machine Learning, Data Structures and Algorithms, System Design, and more, including hands-on projects and real-world applications.</p>
      </>
    ),
  },
  {
    question: "What are the prerequisites for enrolling in a course?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Prerequisites vary by course. Some beginner courses require no prior knowledge, while advanced courses may require high school knowledge in programming and mathematics.</p>
      </>
    ),
  },
  {
    question: "Do you offer certifications?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Yes, we provide certifications upon successful completion of our courses.</p>
      </>
    ),
  },
  {
    question: "How are the courses structured?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Courses include video lectures, quizzes, take-home exercises, real-world projects, and 1:1 mentorship sessions to ensure comprehensive learning.</p>
      </>
    ),
  },
  {
    question: "How does the mentorship program work?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Our mentorship program offers long-term guidance from industry experts who provide personalized learning paths and career advice.</p>
      </>
    ),
  },
  {
    question: "What is cohort matching?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">We match you with a study group of similar skill levels to ensure collaborative learning and support throughout your course.</p>
      </>
    ),
  },
  {
    question: "How does the Job Search and Application Assistant work?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Our Job Search and Application Assistant automates the job application process by finding relevant jobs and applying on your behalf, allowing you to focus on interviews.</p>
      </>
    ),
  },
  {
    question: "Can I choose which jobs to apply for?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Yes, you can set preferences and criteria for the types of jobs you want to be applied to.</p>
      </>
    ),
  },
  {
    question: "What tools and technologies will I learn?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">You will learn tools and technologies such as PyTorch, Keras, TensorFlow, Hugging Face, OpenAI APIs, and Python, among others.</p>
      </>
    ),
  },
  {
    question: "How can I attend live sessions and seminars?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">You can attend live sessions and seminars through our built-in audio/video conferencing feature on the website.</p>
      </>
    ),
  },
  {
    question: "How do I enroll in a course?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">You can enroll in a course by subscribing to our email list and get invitation to signing up in our website and selecting the course you wish to take.</p>
      </>
    ),
  },
  {
    question: "What payment methods do you accept?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">We accept various payment methods, including credit/debit cards, PayPal, and bank transfers.</p>
      </>
    ),
  },
  {
    question: "Do you offer any discounts or scholarships?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Yes, we offer discounts and scholarships based on various criteria. Please check our website for more details.</p>
      </>
    ),
  },
  {
    question: "How do you ensure the quality of your courses?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Our courses are designed and taught by industry veterans and researchers, with continuous updates and improvements based on feedback and industry trends.</p>
      </>
    ),
  },
  {
    question: "Can I get a refund if I’m not satisfied with a course?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">Yes, we offer a refund policy within a specified period. Please refer to our refund policy on the website for more details.</p>
      </>
    ),
  },
  {
    question: "How can I contact support?",
    answer: (
      <>
        <p className="mb-2 pl-5 pr-5 text-richYellow text-gray-400">You can contact our support team via email contact @ neugence.ai, phone, or live chat on our website.</p>
      </>
    ),
  },
];

const FaqSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
      <div className="p-4 rounded-lg bg-gray-800 px-16 mx-auto my-5 " id="faq" role="tabpanel" aria-labelledby="faq-tab "
           style={{maxWidth: '900px'}}>
        <div id="accordion-flush" data-accordion="collapse" data-active-classes=" bg-richBlack text-gray-100 text-white"
             data-inactive-classes="text-gray-100 text-gray-100">
          {faqData.map((item, index) => (
              <div key={index}>
                <h2 id={`accordion-flush-heading-${index}`}>
                  <button
                      type="button"
                      className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-100 border-b border-gray-200 border-gray-700 text-gray-100"
                      onClick={() => toggleFAQ(index)}
                      aria-expanded={openIndex === index}
                      aria-controls={`accordion-flush-body-${index}`}
                  >
                    <span>{item.question}</span>
                    <svg
                        data-accordion-icon
                        className={`w-3 h-3 transform ${openIndex === index ? 'rotate-180' : 'rotate-90'} shrink-0`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                    >
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
                    </svg>
                  </button>
                </h2>
                <div
                    id={`accordion-flush-body-${index}`}
                    className={`${openIndex === index ? '' : 'hidden'}`}
                    aria-labelledby={`accordion-flush-heading-${index}`}
                >
                  <div className="py-5 border-b border-gray-200 border-gray-700 text-white bg-gray-900 ">
                      {item.answer}
                  </div>
                </div>
              </div>
          ))}
        </div>
      </div>
  );
};

export default FaqSection;
