import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { EffectFade, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-coverflow";
import image1 from "../../assets/carousel/carousel1.png";
import image2 from "../../assets/carousel/carousel2.jpg";
import image3 from "../../assets/carousel/carousel3.jpg";
import image4 from "../../assets/carousel/Acehub_bg.png";
// @ts-ignore
import placeholder from "../../assets/carousel/placeholder.mp4";
// works on the site, ignoring the error
const Carousel = () => {
  return (
    <Swiper
      className="md:h-[80vh] md:w-[80%] w-[80%] h-[60vh] rounded-xl"
      modules={[Pagination, Scrollbar, A11y, EffectCoverflow, Autoplay]}
      spaceBetween={50}
      effect="coverflow"
      slidesPerView={1}
      scrollbar={{ draggable: true }}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
        <SwiperSlide className="relative">
            <video className="w-full h-full object-cover" loop autoPlay>
                <source src={placeholder} type="video/mp4"/>
            </video>
            <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-50">
                <div className="md:text-6xl text-xl font-semibold">
                    Upskill in AI ML
                </div>
                <div className="md:text-xl md:font-semibold text-xs text-center md:px-0 px-4">
                    We provide you real world projects, code, notebooks, tutorials and team settings to ramp quickly on Ai.
                </div>
                <div className="learn-more bg-white text-black md:py-2 md:px-4 text-sm px-1 py-1 rounded-full mt-4 hover:cursor-pointer">
                    Learn more
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide
            className=""
            style={{
                backgroundImage: `url(${image2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="text-white flex flex-col items-center md:space-y-4 space-y-2">
                <div className="md:text-6xl md:font-semibold text-xl font-semibold">
                    Job Applications For You
                </div>
                <div className="md:text-xl md:font-semibold text-xs text-center md:px-0 px-4">
                    Auto Job Applications, Provide you Mock Interviews, 1:1 Mentorship & proven path to landing awesome job.
          </div>
          <div className="learn-more bg-white text-black md:py-2 md:px-4 text-sm px-1 py-1 rounded-full hover:cursor-pointer">
            Learn more
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide
        className=""
        style={{
          backgroundImage: `url(${image3})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="text-white flex flex-col items-center md:space-y-4 space-y-2">
          <div className="md:text-6xl text-xl font-semibold">
            Email / Whatsapp / SMS Business Products
          </div>
          <div className="md:text-xl md:font-semibold text-xs text-center md:px-0 px-4">
            Ai Flows to match your content to right audience, measure impact, AB Tested iterations over content and audience to increase sales.
          </div>
          <div className="learn-more bg-white text-black md:py-2 md:px-4 text-sm px-1 py-1 rounded-full hover:cursor-pointer">
            Learn more
          </div>
        </div>
      </SwiperSlide>{" "}
      <SwiperSlide
        className=" backdrop-opacity-60	"
        style={{
          backgroundImage: `url(${image1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

        }}
      >
        <div className="text-white flex flex-col items-center md:space-y-4 space-y-2">
          <div className="md:text-6xl text-xl font-semibold">
            Productivity Tools & Practices for Businesses
          </div>
          <div className="md:text-xl md:font-semibold text-xs text-center md:px-0 px-4">
             We build high productivity teams, drive innovation & create ecosystem for unbounded success.
          </div>
          <div className="learn-more bg-white text-black md:py-2 md:px-4 text-sm px-1 py-1 rounded-full hover:cursor-pointer">
            Learn more
          </div>
        </div>
      </SwiperSlide>
      ...
    </Swiper>
  );
};

export default Carousel;
