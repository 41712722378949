import React, { useEffect, useRef, useState } from "react";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileDropDownOpen, setIsMobileDropDownOpen] = useState(false);
  const ref = useRef(null);
  const headerBar = useRef(null)

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleIsMobileDropDownOpen = () => {
    setIsMobileDropDownOpen(!isMobileDropDownOpen);
  };
  const enter = () => setIsDropdownOpen(true);
  const leave = () => setIsDropdownOpen(false);
  useEffect(() => {
    const refCopy = ref;
    refCopy.current.addEventListener("mouseenter", enter);
    if(headerBar.current) headerBar.current.addEventListener("mouseleave", leave);
    return () => {
      refCopy.current.removeEventListener("mouseenter", enter);
    };
  }, [ref]);

  return (
    <header ref={headerBar} className="fixed top-0 left-0 w-full z-50 overflow-x-hidden">
      <Slide direction="down">
        <nav className="bg-richBlack">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
            <a
              href="https://neugence.ai"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <span className="self-center text-2xl font-semibold whitespace-nowrap font-JetBrainsMono text-richYellow">
                Neugence.Ai
              </span>
            </a>
            <button
              data-collapse-toggle="mega-menu-full"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
              aria-controls="mega-menu-full"
              aria-expanded="false"
              onClick={handleIsMobileDropDownOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div
              id="mega-menu-full"
              className={`items-center justify-between font-medium ${
                isMobileDropDownOpen ? "flex" : "hidden"
              } w-full md:flex md:w-auto md:order-1`}
            >
              <ul className="flex flex-col p-4 md:p-0 mt-4 rounded-lg font-JetBrainsMono text-white md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
                <li>
                  <Link to="/">
                    <a
                      href="#"
                      className="block py-2 px-3 rounded md:hover:bg-transparent md:hover:text-richYellow md:p-0"
                      aria-current="page"
                    >
                      Home
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    <div className="block py-2 px-3 rounded  md:hover:bg-transparent  md:p-0 text-white hover:bg-gray-700 hover:text-richYellow border-gray-700">
                      About
                    </div>
                  </Link>
                </li>
                <li ref={ref}>
                  <button
                    data-collapse-toggle="mega-menu-full-dropdown"
                    className="flex items-center justify-between w-full py-2 px-3 text-gray-900 block py-2 px-3 rounded  md:hover:bg-transparent  md:p-0 text-white hover:bg-gray-700 hover:text-richYellow border-gray-700"
                    onClick={handleDropdownToggle}
                  >
                    Products{" "}
                    <svg
                      className="w-2.5 h-2.5 ms-2.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>
                </li>
                <li>
                  <Link to="/career">
                    <a
                      href="#"
                      className="block py-2 px-3 rounded  md:hover:bg-transparent  md:p-0 text-white hover:bg-gray-700 hover:text-richYellow border-gray-700"
                    >
                      Careers
                    </a>
                  </Link>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 px-3 rounded  md:hover:bg-transparent  md:p-0 text-white hover:bg-gray-700 hover:text-richYellow border-gray-700"
                  >
                    <Link to="/career/apply">
                    Apply
                    </Link>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 px-3 rounded  md:hover:bg-transparent  md:p-0 text-white hover:bg-gray-700 hover:text-richYellow border-gray-700"
                  >

                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 px-3 rounded  md:hover:bg-transparent  md:p-0 text-white hover:bg-gray-700 hover:text-richYellow border-gray-700"
                  >
                    <Link to="/career/reviews">
                    Collaboration
                    </Link>
                  </a>
                </li>
                
                
              </ul>
            </div>
          </div>
          {isDropdownOpen && (
            <div
            ref={ref}
              id="mega-menu-full-dropdown"
              className="mt-1 border-gray-200 shadow-sm bg-gray-900  border-y bg-gray-800 border-gray-600"
            >
              <div className="grid max-w-screen-xl px-4 py-5 mx-auto text-gray-900 text-white sm:grid-cols-2 md:px-6">
                <ul>
                  <li>
                    <a
                      href="#"
                      className="block p-3 rounded-lg hover:bg-gray-100 hover:bg-gray-700"
                    >
                      <div className="font-semibold">Online Stores</div>
                      <span className="text-sm text-gray-500 text-gray-400">
                        Connect with third-party tools that you're already
                        using.
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block p-3 rounded-lg hover:bg-gray-100 hover:bg-gray-700"
                    >
                      <div className="font-semibold">Segmentation</div>
                      <span className="text-sm text-gray-500 text-gray-400">
                        Connect with third-party tools that you're already
                        using.
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block p-3 rounded-lg hover:bg-gray-100 hover:bg-gray-700"
                    >
                      <div className="font-semibold">Marketing CRM</div>
                      <span className="text-sm text-gray-500 text-gray-400">
                        Connect with third-party tools that you're already
                        using.
                      </span>
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      href="#"
                      className="block p-3 rounded-lg hover:bg-gray-100 hover:bg-gray-700"
                    >
                      <div className="font-semibold">Online Stores</div>
                      <span className="text-sm text-gray-500 text-gray-400">
                        Connect with third-party tools that you're already
                        using.
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block p-3 rounded-lg hover:bg-gray-100 hover:bg-gray-700"
                    >
                      <div className="font-semibold">Segmentation</div>
                      <span className="text-sm text-gray-500 text-gray-400">
                        Connect with third-party tools that you're already
                        using.
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block p-3 rounded-lg hover:bg-gray-100 hover:bg-gray-700"
                    >
                      <div className="font-semibold">Marketing CRM</div>
                      <span className="text-sm text-gray-500 text-gray-400">
                        Connect with third-party tools that you're already
                        using.
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </nav>
      </Slide>
    </header>
  );
};

export default NavBar;
