import { Link } from "react-router-dom";
import "./Team.css";

import people from './people';

export default function Team() {
  return (
    <div className="text-white  sm:py-32 justify-self">
      <div
        className="mx-auto grid max-w-8xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3 flex
      justify-center "
      >
        <div className="max-w-2xl lg:p-8 h-[520px] mb-20">
          <h2 className="text-3xl font-bold  tracking-tight text-richYellow sm:text-4xl">
            Meet our Team
          </h2>
          <p className="mt-6 text-lg leading-8 ">
            Our team is composed of talented individuals from prestigious Indian
            and international institutions such as IIM, IIT, NIT, BITS, SRM, VIT, DU,
            IP, and more. Our members are not only accomplished hackathon
            champions and coding competition winners but also innovative student
            entrepreneurs and much more.
            <br />
            <br />
            You can join us and become part of this dynamic and driven team,
            where your skills and passion will be nurtured and celebrated.
            Together, we push boundaries, achieve excellence, and create
            impactful solutions.
          </p>
        </div>
        <ul
          role="list"
          className=" grid gap-y-8 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 sm:gap-y-16 gap-x-8 xl:col-span-2  lg:p-8 h-[520px] flex flex-wrap justify-center items-center overflow-auto scroll-smooth scrollbar"
        >
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex  items-center gap-x-8  px-12 py-4 cursor-pointer hover:bg-gray-900 mr-4">
                <img
                  alt={person.name}
                  src={person.imageUrl}
                  className="h-16 w-16 rounded-full"
                />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight ">
                    {person.name}
                  </h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">
                    {person.role}
                  </p>
                  <p className="text-sm font-normal leading-5 tracking-tight text-white">
                    {person.university}
                  </p>
                  <a
                    className="text-sm font-normal leading-5 tracking-tight text-gray-500 cursor-pointer"
                    href={person.linkedinLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
