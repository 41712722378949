import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import Carousel from "../refactor/Carousel.tsx";
import EmailSubscribe from "../MailChamp/EmailSubscribe.js";
import HeroUpSkillAi from "../New/HeroUpSkillAi.js";

import Features from "../New/Features.js";

import News from "../New/News.js";
import Team from "../New/Team.js";
import Test from "../New/Testimonials.js";
import FaqSection from "../New/Faq.js";
import AceHubImage from "../../assets/hero/image.png";
import HeroImage from "../../assets/hero/image2.png";
import HeroImageDummy from "../../assets/hero/image5.png";
const LandingPage = () => {
  const slideInFromLeft = {
    hidden: { x: "-100vw", opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { type: "tween", duration: 1.3 } },
  };

  const slideInFromRight = {
    hidden: { x: "100vw", opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { type: "tween", duration: 1.3 } },
  };
  const AnimatedComponent = ({ children, variants }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={variants}
        style={{ opacity: 0, willChange: "transform, opacity" }}
      >
        {isInView && children}
      </motion.div>
    );
  };

  const FeatureSection = [
    {
      Heading: "UpSkill In AI",
      SubHeading:
        "Bachelors, Masters, PhD & Professional Level tracks available.",
      Content: `Learn PyTorch, Keras, Tensorflow, Hugging Face, OpenAi APIs,
              Python or you name it, we will enable you. We dive deep into code,
              give you quizzes, take home exercises, real world impactful
              projects to ensure your skill levels are upgraded.`,
      Image: AceHubImage,
      Direction: ``,
    },
    {
      Heading: "Auto Apply to Jobs",
      SubHeading: `Job Search and Application Assistant`,
      Content: `Tired of tedius job search and application process! Preserve your
              energy and focus on interviews instead. We at ApplyJobs.ai find
              you the most relevant jobs to your resume and apply on your
              behalf. We can apply 300 or more jobs per month on your behalf so
              that you remain focused on interviews and make your next career
              move.`,
      Image: `https://s3.amazonaws.com/i.snag.gy/QTbhEG.jpg`,
      Direction: `row-reverse`,
    },
    {
      Heading: "Reach Your Audience",
      SubHeading: `With our State of The Art Whatsapp / Email / SMS Marketing &
                Chatbot Solutions`,
      Content: `500,000 emails sent. Quizzing on Whatsapp, response validation &
              evaluations available. Chatbot response in Whatsapp.`,
      Image: HeroImage,
      Direction: ``,
    },

    {
      Heading: "Boost your team's productivity",
      SubHeading: ``,
      Content: `We built Acehub.ai, ApplyJobs.ai, MailChamp.ai, RealTime Chatbots,
              WebGL Model Deployments, Video / Audio Conferencing, Socket
              Communications, Scalable & Fast Notes App in house. If you are
              interested in leveraging our collaboration, ideating, tracking
              tools connect with us.`,
      Image: HeroImageDummy,
      Direction: `row-reverse`,
    },
  ];

  return (
    <div className="overflow-x-hidden w-full">
      <div className="mt-20">
        <AnimatedComponent variants={slideInFromLeft}>
          <Carousel />
        </AnimatedComponent>
      </div>
      <AnimatedComponent variants={slideInFromLeft}>
        <HeroUpSkillAi data={FeatureSection[0]} />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromRight}>
        <HeroUpSkillAi data={FeatureSection[1]} />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromLeft}>
        <EmailSubscribe />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromRight}>
        <Features />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromLeft}>
        <EmailSubscribe />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromRight}>
        <HeroUpSkillAi data={FeatureSection[2]} />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromLeft}>
        <HeroUpSkillAi data={FeatureSection[3]} />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromRight}>
        <News />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromLeft}>
        <Team />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromRight}>
        <Test />
      </AnimatedComponent>
      <AnimatedComponent variants={slideInFromLeft}>
        <FaqSection />
      </AnimatedComponent>
    </div>
  );
};

export default LandingPage;
