import React, { useState,useEffect } from "react";

const FloatingCookie = ( ) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted) return;

    
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        if (data.country_code !== 'IN') {
          setIsVisible(true);
        }
      })
      .catch(error => console.error('Error fetching location:', error));
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;
    
  return (
    
    
    
    <div
      id="informational-banner"
      tabindex="-1"
      class="fixed bottom-0 rounded-tr-2xl start-0 z-[1] flex flex-col justify-between w-11/12 p-4 border-b border-gray-200 md:flex-row bg-gray-50 dark:bg-gray-800 dark:border-gray-800"
    >
      <div class="mb-4 md:mb-0 md:me-4">
        <h2 class="mb-0.5 text-base font-semibold text-gray-900 dark:text-white">
          Neugence.Ai
        </h2>
        <p className="text-white opacity-80">We use cookies to improve your experience on our site. By accepting, you agree to our use of cookies.</p>
      </div>
      <div class="flex items-center flex-shrink-0">
        <a
          href="#"
          class="inline-flex items-center justify-center px-3 py-2 me-3 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          <svg
            class="w-3 h-3 me-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M9 1.334C7.06.594 1.646-.84.293.653a1.158 1.158 0 0 0-.293.77v13.973c0 .193.046.383.134.55.088.167.214.306.366.403a.932.932 0 0 0 .5.147c.176 0 .348-.05.5-.147 1.059-.32 6.265.851 7.5 1.65V1.334ZM19.707.653C18.353-.84 12.94.593 11 1.333V18c1.234-.799 6.436-1.968 7.5-1.65a.931.931 0 0 0 .5.147.931.931 0 0 0 .5-.148c.152-.096.279-.235.366-.403.088-.167.134-.357.134-.55V1.423a1.158 1.158 0 0 0-.293-.77Z" />
          </svg>{" "}
          Learn more
        </a>
        <button
          onClick={acceptCookies}
          class="inline-flex items-center justify-center px-3 py-2 me-2 text-xs text-white bg-richYellow rounded-lg hover:opacity-80 focus:ring-4 focus:ring-blue-300 dark:bg-richYellow dark:hover:opacity-70 focus:outline-none dark:focus:ring-blue-800 font-semibold"
        >
          Accept Cookies{" "}
          <svg
            class="w-3 h-3 ms-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
        <button
          data-dismiss-target="#informational-banner"
          type="button"
          class="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={()=>setIsVisible(false)}
          
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close banner</span>
        </button>
      </div>
    </div>
    
    
  );
};

export default FloatingCookie;
