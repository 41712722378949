// import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline';

import {FingerPrintIcon, UserGroupIcon, VideoCameraIcon, AcademicCapIcon, PresentationChartBarIcon, CodeIcon, SparklesIcon} from "@heroicons/react/solid";

const features = [
  {
    name: '1:1 Mentorship',
    description:
      'We curate personalized learning paths for you and provide long term guidance with help of industry expert mentors.',
    icon: AcademicCapIcon,
    icon2: AcademicCapIcon,
  },
  {
    name: 'Cohort Matching & Retention',
    description:
      'We pair you with a study group that matches your desired skill level, ensuring you stay motivated and achieve your goals without having to learn alone. Our cohorts of five or more individuals share the same objectives, so you’ll always have support and collaboration on your learning journey.',
     icon: UserGroupIcon,
  },{
    name: 'Artificial Intelligence',
    description:
      'Bachelors, Masters, Phd and Industry Level courses, study materials, projects. ' +
        'Innovative new ideas and out of the box projects in progress.',
    icon: SparklesIcon,
  },
  {
    name: 'Data Structure and Algorithm',
    description:
      'Highly effective success record of our curated set of problems. Continuous evaluation of your upskilling in DSA & Guidance to get you ready for all Online Assessments, All Coding Rounds and Graduate level DSA research topics.',
    icon: CodeIcon,
  },
  {
    name: 'System Design',
    description:
      'At our core, we design and build large-scale systems, and our teaching methods are even better. Our hands-on system design courses feature live discussions, interactive decision-making, and in-depth analysis of pros and cons. Learn from materials crafted by industry veterans and top researchers, ensuring you gain real-world skills and insights.',
    icon: PresentationChartBarIcon,
  },
  {
    name: 'Huddle for Hurdles',
    description:
      "6000+ hours huddles done using our top-notch audio, video, and screen sharing tools. You can launch meetings, attend live sessions, seminars, and product design discussions—all right on our platform. We keep you engaged and upskilling 24/7 with our continuous learning environment.",
     icon: VideoCameraIcon,
  },
];

export default function Features() {
  return (
    <div className="text-gray-100 py-24 sm:py-32"> {/* Changed to dark mode classes */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">Ace the Race</h2> {/* Changed text color */}
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Everything you need to Upskill Faster
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Unlock your potential and accelerate your learning journey with our cutting-edge tools and resources. Transform your skills and achieve your goals with unparalleled speed and efficiency. Embrace the future of learning and start your path to success today!
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-white">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600 bg-opacity-30">
                    {feature.icon2 ?<>
                        <feature.icon className="h-6 w-6 text-richYellow"/>
                        <feature.icon className="h-6 w-6 text-richYellow"/></>
                      :
                      <feature.icon className="h-6 w-6 text-richYellow"/>
                    }
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-400">{feature.description}</dd>
              </div>
              ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
