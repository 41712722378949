import React, { useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router-dom"
import "./App.css";
import NavBar from "./Components/organs/NavBar";
import FooterComponent from "./Components/New/Footer.js";
import TeamCard from "./Components/New/TeamCard.js";
import LandingPage from "./Components/pages/LandingPage.js";
import Career from "./Components/New/Career.js";
import FloatingCookie from "./Components/New/FloatingCookie.js";
import { Cookie } from "react-bootstrap-icons";
import CookieConsent from "./Components/New/Cookie.js";
import Apply from "./Components/New/Apply.js";
import Reviews from "./Components/New/Reviews.js";
let isLocalRun = false;
const hostname = window.location.hostname;
if (hostname === "localhost" || hostname === "127.0.0.1") {
  isLocalRun = true;
}

function App() {
  function handleClosePrivacyModal() {
    this.setState({ privacyModalOpen: false });
    // localStorage.setItem('privacyAccepted', true);
    // console.log('privacyAccepted', localStorage.getItem('privacyAccepted'))
  }
  const [isopen,setisopen] = useState(true);

  // if (localStorage.getItem('privacyAccepted') == 'false') {
  // 	window.location.href = 'https://applyjobs.ai/privacypolicy';
  // }
  // console.log('privacyAccepted', localStorage.getItem('privacyAccepted'));
  // console.log('privacyModalOpen', this.state.privacyModalOpen);
  return (
    <div className="app-container h-min-screen bg-gradient-to-b from-richBlack to-midnightBlue ">
      <NavBar />
      {/* <Banner/> */}
      {/* <Example /> */}
      <FloatingCookie  /> 
      <CookieConsent/>
      
      <Routes>
        <Route path="/" element={ <LandingPage/> } />
        <Route path="about" element={ <TeamCard/> } />
        <Route path="career" element={ <Career/> } />
        <Route path="career/reviews" element={ <Reviews/> } />
        <Route path="career/apply" element={ <Apply/> } />
      </Routes>
      
      <FooterComponent />
    </div>
  );
}

export default App;
