import React, { useState } from 'react';

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      image: "https://media.licdn.com/dms/image/v2/D4D03AQGdJyaD3LWMqw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1723206409944?e=1728518400&v=beta&t=02UQNmqaCzEeRT0KgQYAKUqmjZGfEBm1U7OCdmUO8sc",
      title: "Some of the best Features that were implemented!",
      description: "Our core values are at the heart of all that we do. They are integrated into our daily work lives and help us to remember our customers always come first, the last thank you should always comes from us.",
      name: "Om Patel",
      role: "Ex- Full Stack Web Developer",
    },
    {
      image: "https://media.licdn.com/dms/image/D5603AQFtXiMX5LNW7w/profile-displayphoto-shrink_400_400/0/1690649822961?e=1727913600&v=beta&t=fzULFypErfjMusL_tDqm8DLsicAFgdr4OIR5vUS8aR4",
      title: "Exceptional design and Hard Work",
      description: "The team's dedication to quality and attention to detail exceeded my expectations. They truly understand the needs of their fellow employee and deliver with precision.",
      name: "Ishaan Khurana",
      role: "Ex- Frontend Web Developer",
    },
    // Add more slides as needed
  ];

  const goNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative flex items-center justify-center bg-gray-900 text-white ">
      <div className="absolute inset-0 flex w-full h-full z-0">
        <div className="w-1/3 bg-gray-800"></div>
        <div className="w-4/6 ml-16 bg-gray-700"></div>
      </div>

      <div className="relative z-10 xl:px-20 px-8 py-20 2xl:mx-auto 2xl:container">
        <h1 className="text-5xl font-bold hidden xl:block leading-tight">What our Ex-Employee are<br />saying</h1>
        <h1 className="text-5xl font-bold xl:hidden block leading-tight lg:leading-10">What our  Ex-Employee  are saying</h1>

        <div className="slider overflow-hidden h-[450px]">
          <div
            className="slide-ana flex transition-transform duration-1000"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div key={index} className="flex-none w-full mt-14 md:flex">
                <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                  <img loading="lazy" src={slide.image} alt="Profile" className="w-full h-full object-cover shadow-lg rounded" />
                  <div className="w-32 hidden md:flex items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-indigo-100 rounded-full">
                    <img loading="lazy" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg" alt="commas" />
                  </div>
                </div>
                <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                  <div>
                    <h1 className="text-2xl font-semibold xl:leading-loose text-richYellow">{slide.title}</h1>
                    <p className="text-base font-medium leading-6 mt-4 text-gray-200">{slide.description}</p>
                  </div>
                  <div className="md:mt-0 mt-8">
                    <p className="text-base font-medium leading-4 text-richYellow">{slide.name}</p>
                    <p className="text-base leading-4 mt-2 mb-4 text-gray-400">{slide.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center mt-8">
          <button
            className="cursor-pointer"
            id="prev"
            onClick={goPrev}
            aria-label="previous slide"
          >
            <img loading="lazy" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonal-svg2.svg" alt="previous" />
          </button>
          <button
            id="next"
            onClick={goNext}
            aria-label="next slide"
            className="cursor-pointer ml-2"
          >
            <img
              className="dark:hidden"
              src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg3.svg"
              alt="next"
            />
            <img
              className="transform rotate-180 w-8 hidden dark:block"
              src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonal-svg2.svg"
              alt="previous"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
